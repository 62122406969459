.clear,
.clearfix:after {
  clear: both;
}
a img,
img {
  height: auto;
  max-width: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  font-size: 15px;
}
li,
ul {
  list-style-type: none;
}
.left {
  float: left;
}
.right {
  float: right;
}
.clearfix:after,
.clearfix:before {
  content: " ";
  display: table;
}
.alignRright {
  float: right;
  margin: 0 0 12px 15px;
}
.alignLeft,
.float-left {
  float: left;
}
.alignLeft {
  margin: 0 15px 12px 0;
}
.alignCenter {
  float: none !important;
  margin: 0 auto;
  display: block;
}
a img,
img {
  border: 0;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.config-errors h3 {
  padding: 10px 0;
  margin: 5px;
}
.config-errors ul {
  padding: 0;
  margin: 0;
}
.config-errors li {
  padding: 10px;
  margin: 5px;
  background-color: #fcc;
  border: 1px solid #c00;
  color: #333;
  font: 15px/20px arial, sans-serif;
}
.config-errors li strong {
  float: right;
}
input[type="text"],
input[type="search"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="email"],
select,
textarea {
  background: #fff;
  border: 1px solid #a7abad;
  color: #222;
  height: 32px;
  line-height: 22px;
  width: 100%;
  font-size: 14px;
  padding: 3px 8px;
  outline: 0;
  font-family: Arial, sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
textarea {
  padding: 6px;
  height: 150px;
}
select {
  line-height: 24px;
  padding: 4px;
  height: 32px;
}
input[type="text"]:focus,
input[type="search"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
select:focus,
textarea:focus {
  background: #fff;
  box-shadow: 0 0 5px #51cbee;
  border: 1px solid #51cbee;
}
input.has-error,
select.has-error {
  background: url(../images/error-input.png) 99.5% 50% no-repeat #fff;
  border: 1px solid orange;
}
input.no-error,
select.no-error {
  background: url(../images/tick-input.png) 99.5% 50% no-repeat #fff;
  border: 1px solid green;
}
input.loading,
select.loading {
  background: url(../images/ajax-loader.gif) 99.5% 50% no-repeat #fff;
  border: 1px solid #51cbee;
}
.app-benchmark {
  position: fixed;
  width: 98%;
  left: 0;
  bottom: 0;
  padding: 5px 1%;
  background-color: #ccc;
  color: #333;
  text-align: right;
}
#app_common_modal,
#error_handler_overlay {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  background: #333;
  background: rgba(255, 255, 255, 0.8);
  display: none;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
#app_common_modal .app_modal_body,
#error_handler_overlay .error_handler_body {
  max-width: 600px;
  -webkit-background-clip: padding-box;
  font-family: Verdana, Geneva, sans-serif;
  box-sizing: border-box;
  outline: 0;
}
#error_handler_overlay .error_handler_body {
  margin: 100px auto;
  width: 95%;
  padding: 20px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
#app_common_modal_close,
#error_handler_overlay_close {
  position: absolute;
  right: -10px;
  top: -10px;
  color: #fff;
  background-color: #333;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  font-weight: 700;
  line-height: 30px;
  padding: 0;
  margin: 0;
}
#app_common_modal .app_modal_body {
  margin: 100px auto;
  min-width: inherit;
  width: 95%;
  min-height: 400px;
  padding: 2.5%;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
#app_common_modal .app_modal_body iframe {
  min-height: 400px;
  width: 100%;
  border: 1px solid #d5d6ef;
}
.exitpop-content {
  position: fixed;
  height: 400px;
  width: 708px;
  margin: -200px 0 0 -354px;
  top: 50%;
  left: 50%;
  text-align: left;
  padding: 0;
  border: none;
  z-index: 2147483647;
}
.exitpopup-overlay {
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483647;
  display: none;
}
.exitpop-content img {
  display: block;
  margin: 0 auto;
  position: relative;
  text-align: center;
  max-width: 100%;
  height: auto;
}
.exitpop-discountbar {
  background-color: red;
  border-bottom: 4px dashed #fff;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 9999;
  display: none;
}
.app-load-spinner {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100px;
  height: 100px;
  margin: auto;
  background-color: #333;
  border-radius: 100%;
}
.clear {
  line-height: 0;
  height: 0;
}
.button,
button,
input[type="button"],
input[type="submit"] {
  border: 0;
  width: 100%;
  display: block;
  cursor: pointer;
  position: relative;
  outline: 0;
  padding: 20px 15px;
  background: #2288d0;
  color: #fff;
  font-size: 20px;
}
#loading-indicator::after,
#loading-indicator::before {
  box-sizing: border-box;
  left: 50%;
  position: absolute;
  top: 50%;
}
.button:hover,
button:hover,
input[type="button"]:hover,
input[type="submit"]:hover {
  background: #2791e4;
}
.defult {
  padding: 10px 15px;
}
.defult form label {
  width: 175px;
  display: inline-block;
}
.defult input[type="text"],
.defult input[type="search"],
.defult input[type="number"],
.defult input[type="password"],
.defult input[type="tel"],
.defult input[type="email"],
.defult select,
.defult textarea {
  max-width: 275px;
}
.defult .button,
.defult button,
.defult input[type="button"],
.defult input[type="submit"] {
  max-width: 450px;
}
.all-card-types li {
  float: left;
  margin-right: 20px;
}
#loading-indicator {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  box-sizing: border-box;
  font-size: 1px;
  height: 100%;
  left: 0;
  margin: 0 !important;
  padding: 0 !important;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2147483646;
}
#loading-indicator::before {
  background: url(../images/loading.gif) center center no-repeat
    rgba(0, 0, 0, 0);
  content: "";
  height: 70px;
  margin-left: -35px;
  margin-top: -70px;
  width: 70px;
  z-index: 2;
}
#loading-indicator::after {
  background: #fff;
  border-radius: 5px;
  color: #000;
  content: "Processing, one moment please... ";
  font-family: arial;
  font-size: 17px;
  height: 110px;
  line-height: 98px;
  margin-left: -150px;
  margin-top: -75px;
  padding-top: 35px;
  text-align: center;
  width: 300px;
  z-index: 1;
}
@-webkit-keyframes scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
span.cb-inline-error {
  margin-top: 10px;
  font-weight: 100;
  color: #c00;
  display: block;
}
form {
  padding: 10px;
}

.modal-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}
.modal-dilog-part {
  width: 500px;
  background-color: #fff;
  border-radius: 4px;
  margin: 50px auto 50px auto;
}
.transaction-modal-head {
  padding: 30px 55px;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  position: relative;
}
.transaction-modal-head-img {
  width: 85px;
}
.transaction-heading {
  color: #212d3d;
  font-size: 20px;
  font-weight: 600;
  padding-left: 12px;
}
.transaction-heading span {
  display: block;
  color: #ed4c4c;
  font-size: 15px;
  font-weight: normal;
  margin-top: 12px;
}
.close-btn {
  padding: 5px;
  width: auto;
  background-color: transparent;
  position: absolute;
  right: 30px;
  top: 25px;
}
.close-btn:hover {
  background-color: transparent;
}
.padding-box {
  padding: 30px;
}
.security-heading {
  color: #5e6473;
  font-size: 15px;
  margin-bottom: 20px;
  text-align: center;
}
.ask-cell {
  color: #5e6473;
  font-size: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.form-row {
  margin-bottom: 20px;
}
input[type="text"].form-input,
input[type="search"].form-input,
input[type="number"].form-input,
input[type="password"].form-input,
input[type="tel"].form-input,
input[type="email"].form-input,
select.form-input,
textarea.form-input {
  border: 1px solid #ced4da;
  height: 38px;
  border-radius: 4px;
  padding: 0 15px;
}
.flex-row {
  display: flex;
  margin: 0 -10px;
}
.half-col {
  width: 50%;
  padding: 0 10px;
}
.d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.submit-btn {
  display: inline-block;
  width: auto;
  background-color: #5c79ff;
  padding: 13px 15px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
}
.thank-link {
  color: #5c79ff;
  font-size: 15px;
  margin-right: 15px;
  text-decoration: none;
}
.total-price {
  color: #212d3d;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
}
.info-wrap {
  position: relative;
}
.info-cell {
  position: absolute;
  top: 12px;
  right: 12px;
}
.select-wrap {
  position: relative;
}
.select-wrap select {
  -webkit-appearance: none;
}
.select-wrap:after {
  top: 17px;
  right: 12px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(94, 100, 115, 0);
  border-top-color: #5e6473;
  border-width: 6px;
}
.info-cell:hover .tooltip {
  display: block;
}
.tooltip {
  position: absolute;
  border: 1px solid #ddd;
  padding: 5px;
  font-size: 12px;
  background-color: #fff;
  left: 100%;
  min-width: 90px;
  max-width: 150px;
  margin-left: 10px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: none;
}
.tooltip:after,
.tooltip:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.tooltip:after {
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #fff;
  border-width: 6px;
  margin-top: -6px;
}
.tooltip:before {
  border-color: rgba(221, 221, 221, 0);
  border-right-color: #ddd;
  border-width: 7px;
  margin-top: -7px;
}
.submit-btn:hover {
  background-color: #849aff;
}

.order-btn-freeshipping{
  display: none;
  color: #f05a23;
  font-weight: 600;
}